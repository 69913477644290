import './Footer.css'

const Footer = () => {
    return ( 
        <div className="footer">
            <footer>&copy; 2021 NTU Toilet App</footer>
        </div>
     );
}
 
export default Footer;