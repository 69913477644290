import ToiletForm from '../ToiletForm'

const FormPopup = (props) => {
  
  
    return (
        
            <div className="FormPopup">
              <ToiletForm/>
            </div>
          
    )
}


export default FormPopup